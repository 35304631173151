<template>
  <b-sidebar
    id="sidebar-overview"
    title="Sidebar"
    no-header
    body-class="bg-white"
    width="50%"
    right
    backdrop
    no-close-on-backdrop
    no-close-on-esc
    shadow="lg"
    :visible="visible"
    @hide="closeModal()"
  >
    <div class="flex justify-start border-[#c2c2c2] py-[5px] border-b-[1px]">
      <div class="flex gap-2 items-center">
        <span
          class="k-close cursor-pointer text-[50px] text-[#C2C2C2]"
          @click="closeModal()"
        />
        <div class="text-[20px] font-semibold text-[#333333] ">
          Detail Issue
        </div>
      </div>
    </div>
    <div class="text-[#626262] font-semibold mt-[10px] px-6">
      Partner Information
    </div>
    <div class="flex flex-col gap-4 px-6 mt-7">
      <div class="flex justify-between w-full ">
        <div class="text-[#626262]">
          Full name
        </div>
        <div class="text-[#333333]">
          {{ fullname }}
        </div>
      </div>
      <div class="flex justify-between w-full">
        <div class="text-[#626262]">
          Email
        </div>
        <div class="text-[#333333]">
          {{ email }}
        </div>
      </div>
      <div class="flex justify-between w-full">
        <div class="text-[#626262]">
          Card name
        </div>
        <div class="text-[#333333]">
          {{ cardname }}
          <span class="text-[#828282]">****{{ lastNumber }}</span>
        </div>
      </div>
      <div class="flex justify-between w-full items-center">
        <div class="text-[#626262]">
          Status
        </div>
        <div
          v-if="history.on_solved_at !== null"
          class="text-[#34A770] rounded-sm border flex gap-1 items-center border-[1px] p-[3px]"
        >
          <span class="k-tick-circle text-[16px]" />
          <div class="text-[14px]">
            Solved
          </div>
        </div>
        <div
          v-else-if="history.on_process_at !== null"
          class="text-[#FBA63C] rounded-sm border flex gap-1 items-center border-[1px] p-[3px]"
        >
          <span class="k-status text-[16px]" />
          <div class="text-[14px]">
            On Process
          </div>
        </div>
        <div
          v-else
          class="flex gap-1 relative items-center px-[6px] cursor-pointer  border-[1px] border-[#E2E2E2] rounded-md"
          @click="openDropdown = !openDropdown"
        >
          <div class="text-[#E31A1A] flex gap-1 pr-[6px] items-center py-[4px] border-r-[1px] border-[#E2E2E2]">
            <span class="k-warning-2 text-[16px]" />
            <div>New Issue</div>
          </div>
          <span class="k-arrow-down-1" />
          <div
            v-if="openDropdown"
            class="absolute bg-white whitespace-nowrap hover:bg-gray-400 text-[#626262] border-[1px] text-[14px] rounded-md border-[#E2E2E2] flex justify-center py-[6px] w-[150px] -left-4 top-10"
            @click="moveToOnProcess"
          >
            Move to On Process
          </div>
        </div>
      </div>
    </div>
    <hr
      style="border: 2px solid #ebe9f1 !important"
      class="my-1"
    >
    <div class="px-6 flex items-center justify-between text-[#626262]">
      <div class="font-semibold">
        Balance
      </div>
      <div
        class="flex items-center gap-2 bg-[#F8F8F8] px-1 rounded-md"
        :class="diffBalance !== 0 ? 'text-[#E31A1A]' : 'text-[#34A770]'"
      >
        <img
          :src="diffBalance !== 0 ? 'https://storage.googleapis.com/komerce/icon/ie7/info-circle.svg' : 'https://storage.googleapis.com/komerce/komads-meta/tick-circle.svg'"
          alt="Komerce"
        >
        <span>{{ diffBalance !== 0 ? 'Detected' : 'Not Detected' }}</span>
      </div>
    </div>
    <div class="mx-6 px-[10px] pt-[10px] mt-[10px] border-[#C2C2C2] border-[1px] rounded-[8px]">
      <div
        v-for="item in infoBalance"
        :key="item.key"
        class="flex items-center justify-between text-[#333333] mb-[5px]"
      >
        <div :class="item.type === 'credit' ? 'font-semibold text-[#626262]' : 'text-[#626262]'">
          {{ item.key }}
        </div>
        <div :class="item.type === 'credit' ? 'font-semibold text-[#E31A1A]' : ''">
          {{ currencyFormatter(item.value) }}
        </div>
      </div>
    </div>
    <hr
      style="border: 2px solid #ebe9f1 !important"
      class="my-1"
    >
    <div class="px-6 flex items-center justify-between text-[#626262]">
      <div class="font-semibold">
        Detail Missing Transaction
      </div>
      <BButton
        size="sm"
        class="px-[10px] text-[#F95031] rounded-md !flex !items-center"
        variant="outline-primary"
        :disabled="infoTrx.total_missing === 0 ? true : false"
        @click="toMissingTrx"
      >
        <div>List Transaction</div>
        <img
          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/arrow-right-2.svg"
          alt="Komerce"
          width="15"
        >
      </BButton>
    </div>
    <div class="mx-6 px-[10px] pt-[10px] mt-[10px] border-[#C2C2C2] border-[1px] rounded-[8px]">
      <div class="flex items-center justify-between text-[#333333] mb-[5px]">
        <div class="text-[#626262]">
          Missing Transaction
        </div>
        <div>
          {{ infoTrx.total_missing }} Transaction
        </div>
      </div>
      <div class="flex items-center justify-between text-[#626262] mb-[5px]">
        <div>
          Missing Amount
        </div>
        <div class="font-semibold text-[#E31A1A]">
          {{ currencyFormatter(infoTrx.total_amount_missing) }}
        </div>
      </div>
    </div>
    <hr
      style="border: 2px solid #ebe9f1 !important"
      class="my-1"
    >
    <div class="mx-6 mt-[26px] border-[#C2C2C2] border-[1px] rounded-[8px]">
      <div class="p-[10px] font-semibold text-[#626262]">
        Activity
      </div>
      <div class="bg-[#f8f8f8] p-[10px] rounded-b-[8px]">
        <div
          v-if="history.new_issue_at"
          class="flex justify-between"
        >
          <div class="flex items-center gap-1">
            <div class="bg-[#C2C2C2] rounded-full h-[6px] w-[6px]" />
            <div class="text-[#626262]">
              Issue added
            </div>
          </div>
          <div class="text-[#828282]">
            {{ DDMMYYYY(history.new_issue_at) }}
            {{ HHMMSS(history.new_issue_at) }}
          </div>
        </div>
        <div
          v-if="history.on_process_at"
          class="flex justify-between mt-[16px]"
        >
          <div class="flex items-center gap-1">
            <div class="text-[#626262] gap-1">
              <div
                class="bg-[#C2C2C2] inline-block mb-[1px] rounded-full h-[6px] w-[6px]"
              />
              <div class="inline-block ml-[3px]">
                Status changed from
              </div>
              <div class="bg-[#E31A1A]  ml-[3px] inline-block h-3 w-3" />
              <div class="inline-block  ml-[3px]">
                New Issue to
              </div>
              <div class="bg-[#FBA63C]  ml-[3px] h-3 w-3 inline-block" />
              <div class=" ml-[3px] inline-block">
                On Process
              </div>
            </div>
          </div>
          <div class="text-[#828282]">
            {{ DDMMYYYY(history.on_process_at) }}
            {{ HHMMSS(history.on_process_at) }}
          </div>
        </div>
        <div
          v-if="history.on_solved_at"
          class="flex justify-between mt-[16px]"
        >
          <div class="flex items-center gap-1">
            <div class="text-[#626262] gap-1">
              <div
                class="bg-[#C2C2C2] inline-block mb-[1px] rounded-full h-[6px] w-[6px]"
              />
              <div class="inline-block ml-[3px]">
                Status changed from
              </div>
              <div class="bg-[#FBA63C] ml-[3px] inline-block h-3 w-3" />
              <div class="inline-block  ml-[3px]">
                On Process to
              </div>
              <div class="bg-[#34A770]  ml-[3px] h-3 w-3 inline-block" />
              <div class=" ml-[3px] inline-block">
                Solved
              </div>
            </div>
          </div>
          <div class="text-[#828282]">
            {{ DDMMYYYY(history.on_solved_at) }}
            {{ HHMMSS(history.on_solved_at) }}
          </div>
        </div>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { DDMMYYYY, HHMMSS } from '@/libs/filterDate'
import moment from 'moment'
import { newAxiosIns } from '@/libs/axios'
import { toastError, toastSuccess } from '@/libs/toastification'

moment.locale('en')

export default {
  props: {
    id: {
      default: null,
      type: Number,
    },
    visible: {
      default: false,
      type: Boolean,
    },
    fullname: {
      default: '',
      type: String,
    },
    email: {
      default: '',
      type: String,
    },
    cardname: {
      default: '',
      type: String,
    },
    status: {
      default: '',
      type: String,
    },
    lastNumber: {
      default: '',
      type: String,
    },
    history: {
      default: null,
      type: Object,
    },
    cardId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: {
        moveToOnProcess: false,
      },
      DDMMYYYY,
      openDropdown: false,
      HHMMSS,
      infoBalance: null,
      infoTrx: {
        total_missing: null,
        total_amount_missing: null,
      },
      isLoadingInfoBalance: false,
      isLoadingInfoTrx: false,
      errMessage: 'Unable to load the table data. Please try again later or contact support.',
      diffBalance: null,
    }
  },
  watch: {
    cardId() {
      this.getInfoBalance()
      this.getInfoMissingTrx()
    },
  },
  mounted() {
    if (this.cardId) {
      this.getInfoBalance()
      this.getInfoMissingTrx()
    }
  },
  methods: {
    async moveToOnProcess() {
      try {
        this.loading.moveToOnProcess = true

        const response = await newAxiosIns.request({
          url: '/komcards/api/v1/mitra/ticket/on-process',
          method: 'put',
          data: {
            id: [this.id],
          },
        })

        if (response.status === 200) {
          this.openDropdown = false

          this.$emit('close-modal', 'with-refetch')

          setTimeout(() => {
            toastSuccess('Issue was succesfully move to “On Process”')
          }, 500)
          return
        }
      } catch (error) {
        this.openDropdown = false
        toastError(this.errMessage)
      }
    },
    async getInfoBalance() {
      this.isLoadingInfoBalance = true
      const url = `/komcards/api/v1/card/${this.cardId}/info-balance`

      try {
        const { data: { data } } = await newAxiosIns.get(url)
        const balance = [
          { key: 'Komcards balance', value: data?.balance, type: 'debit' },
          { key: 'Monit balance', value: data?.monit_balance, type: 'debit' },
          { key: 'Hold amount', value: data?.hold_balance, type: 'debit' },
          { key: 'Balance difference', value: data?.diff_balance, type: 'credit' },
        ]
        this.diffBalance = data.diff_balance
        this.infoBalance = balance
        this.isLoadingInfoBalance = false
      } catch {
        this.isLoadingInfoBalance = false
        toastError(this.errMessage)
      }
    },
    async getInfoMissingTrx() {
      this.isLoadingInfoTrx = true
      const url = `/komcards/api/v1/mitra/ticket/${this.id}/missing-transaction`

      try {
        const { data: { data } } = await newAxiosIns.get(url)

        this.infoTrx.total_amount_missing = data.total_amount_missing
        this.infoTrx.total_missing = data.total_missing
        this.isLoadingInfoTrx = false
      } catch {
        this.isLoadingInfoTrx = false
        toastError(this.errMessage)
      }
    },
    closeModal() {
      this.openDropdown = false
      this.$root.$emit('bv::toggle::collapse', 'sidebar-overview')
    },
    currencyFormatter(value) {
      return Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    toMissingTrx(value) {
      window.open(`/overview-monitoring/missing-transactions/${this.cardId}`, '_blank')
    },
  },
}
</script>
