<template>
  <b-modal
    id="modal-confirmation-change-status"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    hide-header
    body-class="px-[24px]"
  >
    <div class="gap-[8px] flex items-center mt-[16px]">
      <img
        src="https://storage.googleapis.com/komerce/assets/svg/warning-Info-circle.svg"
        alt="info-circle"
      >
      <div class="text-[#333333] text-[16px] font-semibold mt-[2px]">
        Confirm
      </div>
    </div>
    <div class="flex justify-center mt-[16px] px-[32px] text-[#626262] max-w-[346px]">
      <div>Are you sure you want to move this issue to ”On Process” ?</div>
    </div>
    <div class="flex justify-end gap-[8px] mt-[16px]">
      <button
        variant="outline-secondary"
        class="text-[#333333] px-[10px] py-[5px] border-[1px] border-[#E2E2E2] rounded-md"
        @click="handleClose"
      >
        Cancel
      </button>
      <b-button
        variant="primary"
        @click="onSubmit"
      >
        Move
      </b-button>
    </div>
  </b-modal>
</template>

<script>

export default {
  methods: {
    handleClose() {
      this.$bvModal.hide('modal-confirmation-change-status')
    },
    async onSubmit() {
      this.$emit('handle-submit')
    },
  },
}
</script>

<style scoped></style>
